<template>
   <div class="content-wrapper">
      <!-- Content Header (Page header) -->
      <div class="content-header">
         <div class="container-fluid">
            <div class="row mb-2">
               <div class="col-sm-6">
                  <h1 class="m-0">{{ $t('coupons') }}</h1>
               </div><!-- /.col -->
               <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                     <li class="breadcrumb-item active">{{ $t('dashboard') }}</li>
                     <li class="breadcrumb-item active">{{ $t('coupons') }}</li>
                  </ol>
               </div><!-- /.col -->
            </div><!-- /.row -->
         </div><!-- /.container-fluid -->
      </div>
      <!-- /.content-header -->

      <!-- Main content -->
      <section class="content">
         <div class="container-fluid">
            <!-- /.row -->
            <div class="row">
               <div class="col-12">
                  <div class="card">
                     <div class="card-header">
                        <router-link
                           :to="{ name: 'couponsCreate' }"
                           class="btn btn-success"
                        >
                           <i class="fas fa-plus-circle"></i> {{ $t('create_coupon') }}
                        </router-link>

                        <div class="card-tools">
                           <div class="input-group input-group-sm pt-2" style="width: 350px;">
                              <input
                                 type="text"
                                 name="table_search"
                                 class="form-control float-right"
                                 placeholder="Buscar"
                                 v-model="searchQuery"
                                 @input="handleDelayedSearch">

                              <div class="input-group-append">
                                 <button type="submit" class="btn btn-default">
                                    <i class="fas fa-search"></i>
                                 </button>
                              </div>
                           </div>
                        </div>
                     </div>
                     <!-- /.card-header -->

                     <div v-if="isLoading" class="d-flex justify-content-center">{{ $t('loading') }}</div>

                     <div v-else class="card-body table-responsive p-0">
                        <table class="table table-hover text-nowrap">
                           <thead>
                           <tr>
                              <th>{{ $t('tag') }}</th>
                              <th>{{ $t('percentage') }}</th>
                              <th>{{ $t('total_usage') }}</th>
                              <th>{{ $t('percentage_of_use') }}</th>
                              <th>{{ $t('activation_date') }}</th>
                              <th>{{ $t('expiry_date') }}</th>
                              <th>{{ $t('expiration_date') }}</th>
                              <th>{{ $t('action') }}</th>
                           </tr>
                           </thead>
                           <tbody>
                              <tr v-for="(coupon ,index) in coupons" :key="index">
                                 <td :class="['tooltip-coupon', coupon.isActive ? '' : 'line-through' ]" >
                                    {{ coupon.name }}
                                    <span class="tooltiptext">
                                       {{ remainingDaysOfValidity(coupon.isActive, coupon.expiryDateTimestamp, coupon.activationDateTimestamp) }}
                                    </span>
                                 </td>
                                 <td :class="[ coupon.isActive ? '' : 'line-through' ]" >
                                    {{ coupon.percent }}%
                                    <span style="font-style: italic; color: grey; font-size: 12px">  {{ $t('or') }}  </span>
                                    {{ parseInt(coupon.percent) }}% OFF
                                 </td>
                                 <td :class="[ coupon.isActive ? '' : 'line-through' ]" >
                                    {{ coupon.usageQuantity }}
                                 </td>
                                 <td v-if="coupon.haveUsageLimit" >
                                    <small :class="[ coupon.isActive ? '' : 'line-through' ]" style="font-size: 11px;" >
                                       {{coupon.usageQuantity}} / <b>{{coupon.limit}}</b>
                                    </small>
                                    <span :class="['float-right', coupon.isActive ? '' : 'line-through' ]" style="font-size: 11px;">
                                       <b>{{ `${((coupon.usageQuantity/coupon.limit) * 100).toFixed(1)}%` }}</b>
                                    </span>
                                    <div class="progress-group d-block">
                                       <div class="progress progress-sm">
                                          <div
                                             class="progress-bar bg-success"
                                             :style="{ width: `${(coupon.usageQuantity/coupon.limit) * 100}%`}">
                                          </div>
                                       </div>
                                    </div>
                                 </td>
                                 <td v-else >
                                    <small :class="['badge badge-primary', coupon.isActive ? '' : 'line-through' ]">
                                       <i class="fa fa-infinity"></i>
                                       {{ $t('no_limit') }}
                                    </small>
                                 </td>
                                 <td :class="[ coupon.isActive ? '' : 'line-through' ]" >
                                    {{ coupon.activationDateFormatted }}
                                 </td>
                                 <td :class="[ coupon.isActive ? '' : 'line-through' ]" >
                                    {{ $t(`${coupon.validationStatus}`) }}
                                 </td>
                                 <td :class="[ coupon.isActive ? '' : 'line-through' ]" >
                                    {{ coupon.expiryDateFormatted }}
                                 </td>
                                 <td>
                                    <div class="row">
                                       <router-link
                                          :to="{ name: 'couponsEdit', params: { uuid: coupon.uuid } }"
                                          class="btn btn-sm btn-outline-primary text-bold"
                                       >
                                          <i class="pt-1 fas fa-pencil-alt"></i>
                                          {{ $t('edit') }}
                                       </router-link>
                                       <button
                                          @click.prevent="showConfirmationModal(coupon.uuid)"
                                          class="ml-3 btn btn-sm btn-outline-secondary text-bold"
                                          :disabled="disablingCoupon"
                                       >
                                          <i class="pt-1 fas fa-ban"
                                       ></i>
                                          {{ $t('disable') }}
                                       </button>
                                    </div>
                                 </td>

                                 <ConfirmationModal
                                    :modalId="modalConfirmationActionId"
                                    :message="`${$t('are_you_sure_you_want_to_deactivate_this_coupon')}`"
                                    @actionConfirmed="disableCoupon"
                                    @actionCancelled="$(`#${modalConfirmationActionId}`).modal('hide');"
                                 />

                              </tr>
                           </tbody>
                        </table>
                     </div>
                     <!-- /.card-body -->

                     <div class="card-footer clearfix">
                        <pagination
                           v-if="!isSearching"
                           :totalPages="totalPages"
                           :currentPage="currentPage"
                           :isFirstPage="isFirstPage"
                           :isLastPage="isLastPage"
                           :adjacentPages="adjacentPages"
                           :tokenPages="tokenPages"
                           :isLoading="isLoadingPagination"
                           @page-selected="handlePageSelected"
                        />
                     </div>
                     <!-- /.card-footer -->

                  </div>
                  <!-- /.card -->
               </div>
            </div>
            <!-- /.row -->
         </div>
      </section>
      <!-- /.content -->
   </div>
</template>

<script>
import couponService from "../../../services/coupon-service";
import { useToast } from "vue-toastification";
import { mapState, mapMutations, mapActions } from "vuex"
import Pagination from "../../../components/Pagination.vue";
import ConfirmationModal from "@/modules/dashboard/components/ConfirmationModal.vue";

export default {
   name: "Coupons",
   setup() {
      const toast = useToast()
      return { toast }
   },
   components: {ConfirmationModal, Pagination },
   data(){
      return {
         isLoading: false,
         isLoadingPagination: false,
         selectedCouponUuid: null,
         modalConfirmationActionId: "modal-confirmation-action-coupon",
         searchTimeout: null,
         searchQuery: "",
         isSearching: false,
         adjacentPagesCount: 2,
         disablingCoupon: false
      }
   },
   async created() {
      this.isLoading = true;
      this.isLoadingPagination = true;

      try {
         await this.getAllPaginatedCoupons();

         if (this.tokenPages.length === 0) {
            await this.getInfoPaginationCoupons();
         }

      } finally {
         this.isLoading = false;
         this.isLoadingPagination = false;
      }
   },
   computed: {
      ...mapState({
         coupons: state => state.coupon.coupons.data,
         currentPage: state => state.coupon.coupons.pagination.currentPage,
         tokenPages: state => state.coupon.coupons.pagination.tokenPages,
         totalPages: state => state.coupon.coupons.pagination.totalPages
      }),
      adjacentPages() {
         const currentIndex = this.tokenPages.findIndex((page) => page.page === this.currentPage);

         const startIdx = Math.max(0, currentIndex - this.adjacentPagesCount);
         const endIdx = Math.min(this.tokenPages.length - 1, currentIndex + this.adjacentPagesCount);

         const pages = this.tokenPages.slice(startIdx, endIdx + 1);

         return pages;
      },
      isFirstPage() {
         return this.currentPage === 1;
      },
      isLastPage() {
         return this.currentPage === this.totalPages;
      }
   },
   methods: {
      ...mapActions(['getAllPaginatedCoupons', 'getInfoPaginationCoupons', 'searchCoupons']),
      ...mapMutations({
         setCurrentPage: 'SET_COUPON_CURRENT_PAGE'
      }),
      async disableCoupon () {

         try {
            this.disablingCoupon = true;
            await couponService.disable(this.selectedCouponUuid);

            $(`#${this.modalConfirmationActionId}`).modal('hide');

            await this.getAllPaginatedCoupons();

            this.toast.success(this.$t("disabled_with_success"));
         } catch (error) {
            $(`#${this.modalConfirmationActionId}`).modal('hide');
            this.toast.error(this.$t("disabled_with_failure"));
         } finally {
            this.disablingCoupon = false;
         }
      },
      async handleDelayedSearch() {
         clearTimeout(this.searchTimeout);
         this.isLoading = true;

         this.searchTimeout = setTimeout(async () => {
            if (this.searchQuery.length > 0) {
               this.isSearching = true;
               await this.searchCoupons(this.searchQuery);
               this.isLoading = false;
            } else {
               this.isSearching = false;
               await this.getAllPaginatedCoupons();
               this.isLoading = false;
            }
         }, 1000);
      },
      async handlePageSelected (action) {
         let pageIndex;

         if (action === 'first' && !this.isFirstPage) {
            pageIndex = 1;
         } else if (action === 'prev' && !this.isFirstPage) {
            this.setCurrentPage(this.currentPage - 1);
            pageIndex = this.currentPage;
         } else if (action === 'next' && !this.isLastPage) {
            this.setCurrentPage(this.currentPage + 1);
            pageIndex = this.currentPage;
         } else if (action === 'last' && !this.isLastPage) {
            pageIndex = this.totalPages;
         } else if (typeof action === 'number') {
            pageIndex = action;
         }

         if (pageIndex) {
            const targetPage = this.tokenPages.find((page) => page.page === pageIndex);

            if (targetPage) {
               this.setCurrentPage(targetPage.page);
               await this.getAllPaginatedCoupons({ pageToken: targetPage.token });
            }
         }
      },
      remainingDaysOfValidity(isActiveCoupon, expireDate, activationDate) {
         if (isActiveCoupon) {
            const differenceInMilliseconds = Math.abs((expireDate * 1000) - (activationDate * 1000));
            const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));

            if (differenceInDays >= 0 )
               return `${this.$t('this_coupon_expires_on')} ${differenceInDays} ${this.$t('days')}`;
            else
               return this.$t('coupon_overdue');
         }
         else
            return this.$t('coupon_disable');
      },
      showConfirmationModal(uuid) {
         this.selectedCouponUuid = uuid
         $(`#${this.modalConfirmationActionId}`).modal('show')
      }
   }
}
</script>

<style scoped>

.line-through {
   text-decoration: line-through;
}

.color-black {
   color: #181717;
}

.color-gray {
   color: #868a8a;
}

.tooltip-coupon {
   position: relative; /* Needed to position the tip text */
   cursor: pointer;
}

.tooltip-coupon .tooltiptext {
   visibility: hidden; /* Hides text by default */
   background-color: #333; /* Background color */
   color: #fff; /* Text color */
   text-align: center; /* Center the text */
   border-radius: 4px; /* Round the corners */
   padding: 5px 10px; /* Internal spacing */
   font-size: 13px;
   position: absolute; /* Position in relation to .tooltip */
   z-index: 1; /* Ensures that the tooltip appears above other elements */
   bottom: 90%; /* Adjusts the vertical position */
   left: 50%; /* Centered horizontally */
   transform: translateX(-30%); /* Adjust the center of the tooltip */
   opacity: 0; /* Makes invisible by default */
   transition: opacity 0.3s; /* Adds a smooth transition */
}

.tooltip-coupon:hover .tooltiptext {
   visibility: visible; /* Displays text on mouseover */
   opacity: 1; /* Make the tooltip appear */
}
</style>
